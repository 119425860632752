<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Default</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <!--<div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>-->
      </div>
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Evenements"
            type="gradient-green"
            :sub-title="totalEvents.toString()"
            icon="ni ni-calendar-grid-58"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Inscriptions"
            type="gradient-green"
            :sub-title="totalRegistrations.toString()"
            icon="ni ni-single-02"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Factures"
            type="gradient-green"
            :sub-title="totalPaiedInvoices.toString()"
            icon="ni ni-single-copy-04"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Paiements"
            type="gradient-green"
            :sub-title="totalCompletedPayments.toString()"
            icon="ni ni-money-coins"
          >
          </stats-card>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--6" style="height: 50vh">
      <!--<div class="row">
        <div class="col-xl-8">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 text-white mb-0">Sales value</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a class="nav-link py-2 px-3"
                       href="#"
                       :class="{active: bigLineChart.activeIndex === 0}"
                       @click.prevent="initBigChart(0)">
                      <span class="d-none d-md-block">Month</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link py-2 px-3"
                       href="#"
                       :class="{active: bigLineChart.activeIndex === 1}"
                       @click.prevent="initBigChart(1)">
                      <span class="d-none d-md-block">Week</span>
                      <span class="d-md-none">W</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <line-chart
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>

          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                <h5 class="h3 mb-0">Total orders</h5>
              </div>
            </div>

            <bar-chart
              :height="350"
              ref="barChart"
              :chart-data="redBarChart.chartData"
            >
            </bar-chart>
          </card>
        </div>
      </div>-->
      <!-- End charts-->

      <!--Lists-->
      <!--<div class="row">
        <div class="col-xl-4">
          <card>
            <h5 class="h3 mb-0" slot="header">Team members</h5>

            <user-list></user-list>
          </card>
        </div>

        <div class="col-xl-4">
          <card>
            <h5 class="h3 mb-0" slot="header">To do list</h5>

            <task-list></task-list>
          </card>
        </div>

        <div class="col-xl-4">
          <card>
            <h5 class="h3 mb-0" slot="header">Progress track</h5>

            <progress-track-list></progress-track-list>
          </card>
        </div>
      </div>-->
      <!--End lists-->

      <!--Widgets-->
      <!--<div class="row">
        <div class="col-xl-5">
          <activity-feed></activity-feed>
        </div>
        <div class="col-xl-7">
          <light-table></light-table>
          <div class="card-deck">
            <card gradient="default" no-body="">
              <div class="card-body">
                <div class="mb-2">
                  <sup class="text-white">$</sup> <span class="h2 text-white">3,300</span>
                  <div class="text-light mt-2 text-sm">Your current balance</div>
                  <div>
                    <span class="text-success font-weight-600">+ 15%</span> <span class="text-light">($250)</span>
                  </div>
                </div>
                <button class="btn btn-sm btn-block btn-neutral">Add credit</button>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <small class="text-light">Orders: 60%</small>
                    <base-progress :value="60"
                                   size="xs"
                                   progress-classes="my-2"
                                   type="success"/>
                  </div>
                  <div class="col">
                    <small class="text-light">Sales: 40%</small>
                    <base-progress :value="40"
                                   size="xs"
                                   progress-classes="my-2"
                                   type="warning"/>
                  </div>
                </div>
              </div>

            </card>

            <card gradient="danger">

              <div class="row justify-content-between align-items-center">
                <div class="col">
                  <img src="/img/icons/cards/bitcoin.png" alt="Image placeholder">
                </div>
                <div class="col-auto">
                  <span class="badge badge-lg badge-success">Active</span>
                </div>
              </div>
              <div class="my-4">
                  <span class="h6 surtitle text-light">
                    Username
                  </span>
                <div class="h1 text-white">@johnsnow</div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="h6 surtitle text-light">Name</span>
                  <span class="d-block h3 text-white">John Snow</span>
                </div>
              </div>

            </card>
          </div>
        </div>
      </div>-->
      <!--End Widgets-->

      <!--Tables-->
      <!--<div class="row">
        <div class="col-xl-8">
          <page-visits-table></page-visits-table>
        </div>
        <div class="col-xl-4">
          <social-traffic-table></social-traffic-table>
        </div>
      </div>-->
      <!--End tables-->
    </div>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";

// Components
import BaseProgress from "@/components/BaseProgress";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import StatsCard from "@/components/Cards/StatsCard";

// Lists
import ActivityFeed from "./ActivityFeed";
import TaskList from "./TaskList";
import UserList from "./UserList";
import ProgressTrackList from "./ProgressTrackList";

// Tables
import LightTable from "./LightTable";
import SocialTrafficTable from "./SocialTrafficTable";
import PageVisitsTable from "./PageVisitsTable";
import { INVOICE_STATUS_PAID } from "../../constants/invoices";
import { PAYMENT_STATUS_COMPLETED } from '../../constants/payments';

export default {
  components: {
    //ActivityFeed,
    //LineChart,
    //BarChart,
    //BaseProgress,
    RouteBreadCrumb,
    StatsCard,
    //TaskList,
    //PageVisitsTable,
    //SocialTrafficTable,
    //LightTable,
    //UserList,
    //ProgressTrackList
  },
  data() {
    return {
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: "Performance",
              data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
            },
          ],
          labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Sales",
              data: [25, 20, 30, 22, 17, 29],
            },
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      totalEvents: 0,
      totalRegistrations: 0,
      totalPaiedInvoices: 0,
      totalCompletedPayments: 0,
    };
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            label: "Performance",
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },

    async getEvents() {
      let params = {
        page: {
          number: 1,
          size: 1,
        },
        include: "",
      };
      await this.$store.dispatch("events/list", params);
      this.totalEvents = this.$store.getters["events/listTotal"];
    },

    async getRegistrations() {
      let params = {
        page: {
          number: 1,
          size: 1,
        },
        include: "",
      };
      await this.$store.dispatch("registrations/list", params);
      this.totalRegistrations = this.$store.getters["registrations/listTotal"];
    },

    async getInvoices() {
      let params = {
        page: {
          number: 1,
          size: 1,
        },
        filter: {
          status: INVOICE_STATUS_PAID,
        },
        include: "",
      };
      await this.$store.dispatch("invoices/list", params);
      this.totalPaiedInvoices = this.$store.getters["invoices/listTotal"];
    },

    async getPayments() {
      let params = {
        page: {
          number: 1,
          size: 1,
        },
        filter: {
          status: PAYMENT_STATUS_COMPLETED,
        },
        include: "",
      };
      await this.$store.dispatch("payments/list", params);
      this.totalCompletedPayments = this.$store.getters["payments/listTotal"];
    },
  },
  mounted() {
    this.initBigChart(0);
    this.getEvents();
    this.getRegistrations();
    this.getInvoices();
    this.getPayments();
  },
};
</script>
<style></style>
